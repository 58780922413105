import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

import { BOOKING_URL } from '../../config';

const BookNowButton = (props) => (
    <a href={BOOKING_URL} target="_blank">
        <Button {...props}>{props.children}</Button>
    </a>
);

BookNowButton.PropTypes = {
    children: PropTypes.any.isRequired,
    classes: PropTypes.string,
};

export default BookNowButton;
