import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroBanner from '../components/HeroBanner';
import { FlexContainer, FlexItem } from '../components/Grid';
import Container from '../components/Container';
import BookNowButton from '../components/BookNowButton';
import { Helmet } from 'react-helmet';

export default function Home() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>BARBA | Barbershop in the heart of Preston</title>
                <meta
                    name="description"
                    content="Men’s grooming in the heart of Preston – hair cuts, beard trims, cut throat shaves, facials, products and more – book now"
                />
            </Helmet>
            <Header />
            <HeroBanner />
            <Container
                narrow
                spaced
                classes="u-pt--xlarge u-pb--xlarge u-ta--center"
            >
                <h2 className="u-font--heading-1 u-mb--xlarge">About BARBA</h2>
                <p className="u-font--body-large u-mb--xlarge">
                    BARBA is founded on the strong traditions of barbering. A
                    hub for conversation, community and ritual. Combine this
                    with a dedicated team of individuals solely focused on care
                    and attention to detail and you will see why BARBA will
                    become your home.
                </p>
                <BookNowButton size="large">View services</BookNowButton>
            </Container>
            <FlexContainer
                hasGutters={false}
                classes="c-content-image-row u-fbas--stretch"
            >
                <FlexItem
                    columns={12}
                    medium={6}
                    classes="u-fbo--one u-fbo--two--bp-medium"
                >
                    <div
                        className="c-content-image-row__image"
                        style={{
                            backgroundImage: 'url(/chairs-cloaks.jpg)',
                        }}
                    />
                </FlexItem>
                <FlexItem
                    columns={12}
                    medium={6}
                    classes="u-bgc--secondary u-fbo--two u-fbo--one--bp-medium"
                >
                    <Container>
                        <div className="c-content-image-row__content c-content-image-row__content--dark">
                            <div>
                                <h4 className="u-font--heading-1 u-mb--xlarge">
                                    The Team
                                </h4>
                                <p className="u-font--body-large u-mb--xlarge">
                                    Together the team at BARBA brings over 20
                                    years of experience in the barbering
                                    industry. Our award-winning team is
                                    passionate about delivering outstanding work
                                    and five-star service to each and every
                                    person that walks through our doors.
                                </p>
                            </div>
                        </div>
                    </Container>
                </FlexItem>
            </FlexContainer>
            <FlexContainer hasGutters={false} classes="c-content-image-row">
                <FlexItem
                    columns={12}
                    medium={6}
                    classes="u-bgc--tertiary u-fbo--two u-fbo--one--bp-medium"
                >
                    <div className="c-content-image-row__content">
                        <div>
                            <p className="u-font--heading-2 u-mb--xlarge">
                                Coffee. Cuts. Conversation.
                            </p>
                        </div>
                    </div>
                </FlexItem>
                <FlexItem
                    columns={12}
                    medium={6}
                    classes="u-fbo--one u-fbo--two--bp-medium"
                >
                    <div
                        className="c-content-image-row__image u-bgp--left-center"
                        style={{
                            backgroundImage: 'url(/north-common-barba.jpg)',
                        }}
                    />
                </FlexItem>
            </FlexContainer>
            <Footer />
        </>
    );
}
