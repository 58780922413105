import React from 'react';
import BookNowButton from '../BookNowButton';
import { FlexContainer, FlexItem } from '../Grid';
import Container from '../Container';

const HeroBanner = () => (
    <section className="hero-banner">
        <Container classes="u-ta--center u-ta--left--bp-medium">
            <FlexContainer hasGutters={false}>
                <FlexItem columns={12} medium={6}>
                    <FlexContainer classes="u-fbai--center u-fbjc--center u-h--100">
                        <FlexItem columns={12}>
                            <h1 className="u-font--heading-1 u-mb--regular u-mt--xlarge u-mt--none--bp-medium">
                                "BARBA is founded on the strong{' '}
                                <br className="u-d--none u-d--block--bp-medium" />
                                traditions of barbering."
                            </h1>
                            <h2 className="u-font--heading-2 u-mb--xlarge">
                                Tailored grooming in the heart of Preston
                            </h2>
                            <BookNowButton size="large">Book Now</BookNowButton>
                        </FlexItem>
                    </FlexContainer>
                </FlexItem>
                <FlexItem columns={12} medium={6}>
                    <div>
                        <img
                            className="hero-banner__photo"
                            src={`/nicki-transparent.png`}
                            alt="Nicki header"
                        />
                    </div>
                </FlexItem>
            </FlexContainer>
        </Container>
    </section>
);

export default HeroBanner;
