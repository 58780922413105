import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from '../../utils/dom-utils';

const Button = (props) => {
    const { variant, size, onClick, children, disabled } = props;
    const baseClass = 'c-btn';

    const rootClass = classNames(baseClass, {
        [`${baseClass}--${variant}`]: variant,
        [`${baseClass}--${size}`]: size,
    });

    return (
        <button className={rootClass} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
};

Button.defaultProps = {
    variant: '',
    size: '',
    disabled: false,
};

Button.propTypes = {
    disabled: PropTypes.bool,
    children: PropTypes.any,
    variant: PropTypes.oneOf([
        '',
        'primary',
        'secondary',
        'accent',
        'outline',
        'hollow',
        'hollow-reverse',
        'reverse',
    ]),
    size: PropTypes.oneOf(['', 'xsmall', 'small', 'large']),
    onClick: PropTypes.func,
};

export default Button;
